import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';
import DisplayText from 'components/DisplayText';

import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import {
  calculatePercentage,
  formatNumber,
  formatPercentageSymbol,
} from 'utils/common';

import TopCommunities from './partials/topCommunities/TopCommunities';
import TopLearners from './partials/topLearners/TopLearners';
import Stats from './partials/stats/Stats';
import TotalCourses from './partials/totalCourses/TotalCourses';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Community Name',
    field: 'name',
    sortable: true,
    filterable: false,
    flex: 1,
  },
  {
    headerName: 'Learners',
    field: 'learnerCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.learnerCount);
    },
  },
  {
    headerName: 'Total Courses',
    field: 'courseCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.courseCount);
    },
  },
  {
    headerName: 'Total Started',
    field: 'startedCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.startedCount);
    },
  },
  {
    headerName: 'Total Completed',
    field: 'completedCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      return formatNumber(params.row.completedCount);
    },
  },
  {
    headerName: 'Percent Started',
    field: 'percentageStarted',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      const { percentageStarted } = params.row;

      if (!percentageStarted) return '-';

      return formatPercentageSymbol(percentageStarted.toFixed(2));
    },
  },
  {
    headerName: 'Percent Completed',
    field: 'percentageCompleted',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      const { percentageCompleted } = params.row;

      if (!percentageCompleted) return '-';

      return formatPercentageSymbol(percentageCompleted.toPrecision(2));
    },
  },
];

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Group Name',
  },
];

const loadingState = {
  groups: true,
  exporting: false,
};

const FacilitatorGroupReportGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const theme = useTheme();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getGroups();
  }, [filters, state]);

  const getGroups = async () => {
    setLoading((prevState) => ({ ...prevState, groups: true }));
    try {
      const { response } = await callService({
        resource: httpRoutes.reporting.group.getFacilitatorGroupReport({
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, groups: false }));
    }
  };

  const exportGroups = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource: httpRoutes.reporting.group.exportFacilitatorGroupReport({
          params: { ...state },
          filters: filters,
        }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const formattedData = response.map((item: any) => {
          return {
            'Community Name': item['Community Name'],
            'Total Learners': item['Total Learners'],
            'Total Courses': item['Total Courses'],
            'Total Started': item['Total Started'],
            'Total Completions': item['Total Completions'],
            'Percent Started': calculatePercentage(
              item['Total Started'],
              item['Total Learners'] * item['Total Courses']
            ),
            'Percent Completed': calculatePercentage(
              item['Total Completions'],
              item['Total Learners'] * item['Total Courses']
            ),
          };
        });

        const csv = Papa.unparse(formattedData);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(csvData, 'Facilitator Community Report.csv');
      }
    } catch (e) {
      console.log('Error');
    } finally {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  return (
    <PageContainer>
      <Box sx={{ px: matchDownLG ? 0 : 4, gap: 1 }}>
        <Box sx={{ mb: 2 }}>
          <DisplayText
            type="h2"
            text="Facilitator Community Report"
            variant="semiBold"
            style={{ color: '#162738' }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Box
              sx={{
                p: 4,
                borderRadius: '15px',
                height: '502px',
                flexDirection: 'column',
                width: matchDownLG ? '100%' : '345px',
                backgroundColor: 'white',
                flex: '0.50 0 auto',
                display: 'flex',
                gap: 1,
                overflow: 'auto',
              }}
            >
              <TopCommunities />
            </Box>
            <Box
              sx={{
                flex: '0.50 0 auto',
                width: matchDownLG ? '100%' : '345px',
                p: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                gap: 1,
              }}
            >
              <TopLearners />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 0 auto',
              width: matchDownLG ? '100%' : '345px',
              gap: 1,
            }}
          >
            <Stats />
          </Box>
          <Box
            sx={{
              flexDirection: 'column',
              width: matchDownLG ? '100%' : '588px',
              flex: '1 0 auto',
              display: 'flex',
              gap: 1,
            }}
          >
            <TotalCourses />
          </Box>
        </Box>
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          handleExport={exportGroups}
          loading={loading.groups}
          onRowClick={(params) => {
            navigate(`/reports/groups/${params.id}`);
          }}
        />
      </Box>
    </PageContainer>
  );
};

const FacilitatorGroupReport = () => (
  <ControlledDataGrid>
    <FacilitatorGroupReportGrid />
  </ControlledDataGrid>
);

export default FacilitatorGroupReport;
