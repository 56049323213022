import {
  Grid,
  Step,
  StepButton,
  Stepper,
  useMediaQuery,
  useTheme,
  StepperProps,
} from '@mui/material';

interface StepperType extends StepperProps {
  steps: string[];
  currentStepIndex: number;
  goTo?: any;
  alternativeLabel?: boolean;
  completedSteps?: { [k: number]: boolean };
}

const FormStepper = ({
  steps,
  currentStepIndex,
  goTo = undefined,
  alternativeLabel = false,
  nonLinear = false,
  completedSteps = {},
}: StepperType) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container mb={2} sx={{ width: '100%' }}>
      <Stepper
        alternativeLabel={alternativeLabel}
        activeStep={currentStepIndex}
        nonLinear={nonLinear}
        sx={{
          width: '100%',
          ...(matchDownMD && {
            '& .MuiStep-root:nth-of-type(4) .MuiStepConnector-root': {
              left: 'calc(-50% + -1px)', // Adjust position for the first step
            },
            '& .MuiStep-root:nth-of-type(2) .MuiStepConnector-root': {
              left: 'calc(-50% + -5px)', // Adjust position for the last step
            },
            '& .MuiStepLabel-root' : {
              flexDirection: 'column'
            }
          }),
        }}
      >
        {steps.map((title, index) => (
          <Step key={index} completed={completedSteps[index] === true}>
            <StepButton
              color="inherit"
              onClick={goTo ? () => goTo(index) : undefined}
              sx={{
                ...(matchDownMD && {
                  padding: '24px 15px',
                  margin: '-24px -19px'
                }),
              }}
            >
              {title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};

export default FormStepper;
